var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"workspace"}},[_c('div',{staticClass:"bem-tools"},[_c('div',{staticClass:"outline hovered-node",style:({
        display: !_vm.isDrag && _vm.hover.display ? 'block' : 'none',
        width: _vm.hover.width + 'px',
        height: _vm.hover.height + 'px',
        transform: ("translate(" + (_vm.hover.x) + "px, " + (_vm.hover.y - _vm.frame.scroll) + "px)"),
      })},[_c('div',{staticClass:"breadcrumb"},[_c('div',{staticClass:"crumb"},[_c('div',{staticClass:"inner"},[_vm._v(_vm._s(_vm.hoverInfo.name))])])])]),_c('div',{ref:"outline-selected",staticClass:"outline selected-node",style:({
        display: !_vm.isDrag && _vm.outlineInfo.display ? 'block' : 'none',
        width: _vm.outline.width + 'px',
        height: _vm.outline.height + 'px',
        transform: ("translate(" + (_vm.outline.x) + "px, " + (_vm.outline.y - _vm.frame.scroll) + "px)"),
      })},[_c('div',{staticClass:"breadcrumb"},[_c('div',{staticClass:"crumb"},[(_vm.isCompHierarchyShow)?_vm._l((_vm.compHierarchy),function(comp){return _c('div',{key:comp.id,staticClass:"inner",on:{"mouseover":function($event){return _vm.onCompHover(comp)},"mousedown":function($event){return _vm.onStartDraggingComp(comp)},"click":function($event){return _vm.onCompClick(comp)}}},[_vm._v(" "+_vm._s(comp.name)+" ")])}):_vm._e(),_c('div',{staticClass:"inner",on:{"mousedown":function($event){return _vm.onStartDraggingComp(_vm.outlineInfo.blockInfo)},"click":_vm.toggleCompHierarchy}},[_vm._v(_vm._s(_vm.outlineInfo.blockInfo.name))])],2),_c('div',{staticClass:"close",attrs:{"title":"删除模块"},on:{"click":function($event){return _vm.onCompDeletion(_vm.outlineInfo.blockInfo)}}},[_vm._v(" x ")])])])]),_c('div',{staticClass:"bem-placeholder",class:{ invalid: !_vm.placeholderInfo.isValid },style:({
        display: _vm.placeholderInfo.display ? 'block' : 'none',
        width: _vm.placeholder.width + 'px',
        height: _vm.placeholder.height + 'px',
        transform: ("translate(" + (_vm.placeholder.x) + "px, " + (_vm.placeholder.y - _vm.frame.scroll) + "px)"),
      })}),_c('iframe',{ref:"frame",staticClass:"work-frame",attrs:{"id":"work-frame","src":("/client/" + _vm.pageId),"frameborder":"0"}})])}
var staticRenderFns = []

export { render, staticRenderFns }